import React, { useEffect } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/SearchPage/Header"
import SortBar from "../components/SearchPage/SortBar"
import FlatList from "../components/SearchPage/FlatList"
import { useIntl, navigate } from "gatsby-plugin-react-intl"

const SearchPage = () => {
  const intl = useIntl()
  const lang = intl.locale

  useEffect(() => {
    if (lang === "en") {
      navigate("/search/")
    }
  }, [lang])

  return (
    <Layout>
      <Seo title="Wyszukiwarka" robots="noindex,nofollow" />
      <Header />
      <SortBar />
      <FlatList />
    </Layout>
  )
}

export default SearchPage
